import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { Footer } from '../components/Footer/index';
import { graphql, StaticQuery } from 'gatsby';
import AmssLogoImg from '../images/amsslogo.png';
import { Modal } from '../components/Modal';
import Form from '../images/membership/form.svg';
import Bg from '../images/membership/background2.svg';

export default () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(relativePath: { eq: "membership/bg.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2480) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Layout>
            <Modal open={showModal} setOpen={setShowModal} />
            <Container bg={Bg}>
              <AmssLogo style={{ marginTop: '120px' }} src={AmssLogoImg} alt="" />
              <Wrap>
                <h1 className="title">会员福利与资格</h1>
                <ImageWrap>
                  <img style={{ marginTop: '40px' }} src={Form} alt="" />
                </ImageWrap>
                <FooterText onClick={() => setShowModal(true)}>如何升级为客户级会员</FooterText>
              </Wrap>
            </Container>
            <Footer />
          </Layout>
        );
      }}
    />
  );
};

const ImageWrap = styled.div`
  max-height: 550px;
  overflow: hidden;
`;

const Wrap = styled.div`
  position: relative;
  margin-top: 30px;
  margin-bottom: 70px;
  width: 749px;
  height: 682px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255, 0.85);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 20px;
  p {
    line-height: 1.2;
    font-weight: 400;
    color: #fff;
  }
  .title {
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    text-align: center;
    color: #b50005;
  }
  .sub-title {
    font-size: 18px;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin: 12px 0;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: ${props => `url(${props.bg})`};
  background-repeat: no-repeat;
  background-size: cover;
`;

const AmssLogo = styled.img`
  width: 80px;
`;

const List = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  border-bottom: 1px solid #bdbdbd;
`;

const FooterText = styled.div`
  position: absolute;
  cursor: pointer;
  bottom: 30px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.35px;
  text-align: center;
  color: #b50005;
`;
